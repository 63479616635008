.navbar{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.tab{
    width: 50%;
    border-bottom: 6px solid #cccccc;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
}
.tab:hover{
    background: #ebebeb;
    border-bottom: 6px solid #b7b7b7;
}

.container {
    width: 90%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
}


li {
    position: relative;
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.table_header {
    background-color: #1f6a96;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: white;
}
.table_row {
    background-color: #ffffff;
    display: flex;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
    align-items: center;
}
.table_row > div,  .table_header > div{
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table_row:hover{
    transform: scale(1.01)
}
.col_1 {
    width: 5%;
}
.col_2 {
    width: 40%;
}
.col_2:hover{
    color: #1f6a96;
}
.col_3 {
    width: 30%;
}
.col_4 {
    width: 10%;
}
.col_5 {
    width: 10%;
}
.publish{
    font-size: 20px;
}
span{
    margin-left: 0 !important;
}

span:last-child::after{
    content: "";
}

.addFileContainer{
    width: fit-content;
    margin: 10px 10px 10px auto;
    display: flex;
    align-items: center;
    color: white;
    border-radius: 5px;
    padding: 10px;
    background: #1f6a96;
    font-size: 20px;
    cursor: pointer;
}
.addFileContainer > svg {
    margin-right: 10px;
}

.addFileInput {
    width: 140px;
    color: transparent;
}
.addFileInput::-webkit-file-upload-button {
    visibility: hidden;
}
.addFileInput::before {
    content: 'Загрузить файл';
    color: white;
    text-align: center;
    display: inline-block;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 18px;
}
.custom-file-input:active {
    outline: 0;
}
.bookBtn{
    color: #1f6a96;
}
.bookBtn:hover{
    color: #2a2a2a;
}
.bookId:hover{
    color: #1f6a96;
}
.trash{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: -5%;
    font-size: 20px;
    color: rgba(31, 106, 150, 0.4);
}
.trash:hover{
    color: #1f6a96;
}


.fetch{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.fetch > svg {
    font-size: 100px;
}
.loader{
    font-size: 20px;
    animation: 3s linear infinite rotate;
    color: #1f6a96;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.search{
    margin: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.search > input {
    width: 300px;
    font-size: 20px;
    padding: 10px;
    border: 1px solid #1f6a96;
    outline: none;
    border-radius: 5px 0 0 5px;
}

.search > svg {
    background: #1f6a96;
    padding: 12.5px 12px 12px 12px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
}

.loadBtn{
    width: fit-content;
    padding: 10px 15px;
    font-size: 18px;
    color: white;
    background: #1f6a96;
    margin: 20px auto;
    border-radius: 5px;
    cursor: pointer;
}


.currentBookContainer{
    padding: 20px;
}
.bookTitle{
    width: 60%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    margin: 0 auto 15px;
    padding: 10px;
    box-shadow: 0px 0px 9px 0px rgba(31, 106, 150, 0.4);
    cursor: pointer;
    color: #1f6a96;
}
.subTitle_1{
    position: relative;
    width: 58%;
    margin: 0 auto 15px;
    padding: 10px;
    left: 1%;
    font-weight: bold;
    color: #1f6a96;
    box-shadow: 0 0 9px 0 rgba(31, 106, 150, 0.4);
    cursor: pointer;
}
.subTitle_2{
    position: relative;
    width: 56%;
    left: 2%;
    padding: 10px;
    color: #1f6a96;
    box-shadow: 0 0 9px 0 rgba(31, 106, 150, 0.4);
    margin: 0 auto 15px;
    cursor: pointer;
}
.subTitle_3{
    position: relative;
    width: 54%;
    left: 3%;
    padding: 10px;
    color: #1f6a96;
    box-shadow: 0 0 9px 0 rgba(31, 106, 150, 0.4);
    margin: 0 auto 10px;
    cursor: pointer;
}
.chapterContainer{
    padding: 20px;
    width: 60%;
    margin: 20px auto 0;
    box-shadow: 0 0 9px 0 rgba(31, 106, 150, 0.4);
}
.chapterTitle{
    font-weight: bold;
    margin-bottom: 10px;
}
.passage{
    margin-bottom: 10px;
}
span:first-child{
    margin-left: 20px !important;
}
img{
    max-width: 98%;
    margin: 5px;
}


.modalContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px 20px;
    width: 300px;
    height: fit-content;
    background: #1f6a96;
    color: white;
    border-radius: 5px;
    text-align: center;
}
.modalContainer > div:first-child {
    cursor: pointer;
    text-align: end;
}

.deleteBtn{
    width: 60%;
    margin: 10px auto 0;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    background: #FFFFFF;
    color: #1f6a96;
}

.bookInfoBlock{
    padding: 10px;
    width: 80%;
    margin: auto;
    box-shadow: 0 0 9px 0 rgba(31, 106, 150, 0.4);
}
.infoBookItem{
    position: relative;
    margin-bottom: 10px;
}
.infoBookItem > textarea{
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 10px;
    border: 0.5px solid #1f6a96;
    border-radius: 5px;
    height: fit-content;
    resize: none;
    outline: none;
}
.titleIfo{
    color: #1f6a96;
    font-weight: bold;
}
.annotation{
    padding: 10px;
    margin-top: 10px;
}
.iconUpdate{
    position: absolute;
    color: #1f6a96;
    top: 0;
    left: 100px;
    cursor: pointer;
    font-size: 20px;
}
.cover{
    width: 20%;
}
.addImageContainer{
    width: 105px;
    margin: 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid #B0CFE4;
    font-size: 20px;
    cursor: pointer;
    background: #1f6a96;
}
.addImageInput {
    color: transparent;
}
.addImageInput::-webkit-file-upload-button {
    visibility: hidden;
}
.addImageInput::before {
    content: 'загрузить обложку';
    color: #FFFFFF;
    text-align: center;
    display: inline-block;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 12px;
}
