.homePage{
    box-sizing: border-box;
    width: 100%;
    background-image:
            linear-gradient(135deg, rgba(0,0,0, 0.2) 0%, rgba(0,0,0, 0.1) 25%, rgba(0,0,0, 0.2) 50%, rgba(0,0,0, 0.1) 75%, rgba(0,0,0, 0.2) 100%),
            linear-gradient(90deg, #131F26, #0c3245, #145474);
    background-size: 8px 8px, 100%;
}
.homePage > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.container_1{
    min-height: 100vh;
    padding: 20px;
    color: #FFFFFF;

}
.container_2{
    min-height: 20vh;
    padding-bottom: 70px;
}


.infoBlock{
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.block{
    width: 50%;
    text-align: center;
}
.header{
    font-size: 6rem;
    font-weight: bold;
    color: #28A8E9;
    margin-bottom: 40px;
}
.description{
    font-size: 24px;
    line-height: 1.5;
}
.btnStart{
    width: 40%;
    background: #28A8E9;
    padding: 10px 15px;
    font-size: 22px;
    margin: 30px auto 0;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
}
.btnStart:hover {
    transform: scale(1.05)
}
.btnStart >svg {
    margin-right: 10px;
}
.cardsBlock{
    width: 90%;
    margin: auto;
    padding: 10px 20px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card {
    width: 30%;
    margin: 5px;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    font-size: 18px;
    transition: transform 0.2s ease;
    color: #FFFFFF;
    cursor: pointer;
}

.card > svg {
    width: 40px;
    height: 40px;
    margin: auto;
    color: #28A8E9;
}
.card:hover{
    transform: scale(1.05)
}
.card > div >h2{
    width: 100%;
    font-size: 28px;
    color: #28A8E9;
    text-align: center;
    margin-bottom: 10px;
}
.phoneBorder{
    position: relative;
    width: 300px;
    height: 520px;
    margin: auto;
    background: black;
    border-radius: 20px;
}
.phone{
    width: 290px;
    height: 510px;
    box-sizing: border-box;
    border-radius: 20px;
    border-top: 4px solid black;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: url('../../../shared/pattern.png') repeat,
            #131F26;
    background-size: 300px 480px;
}
.phone > h4 {
    width: 100%;
    height: 10%;
    margin: 0 auto 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: center;
    border-radius: 20px 20px 2px 2px;
    background: #263A44;
}
.headerPhone{
    border-radius: 20px 20px 2px 2px;
    padding: 5px;
    background: #263A44;
    display: flex;
    align-items: center;
}
.logo > svg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: 5px;
}
.startLogo{
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: 50%;
    transform: translate(0, -50%);
}
.messagesBlock{
    height: 85%;
    overflow: scroll;
    position: relative;
}
.messagesBlock::-webkit-scrollbar {
    display: none;
}
.messageGroup{
    margin-top: 20px;
}
.dateMessage{
    width: 60px;
    padding: 5px;
    margin: auto;
    border-radius: 10px;
    font-size: 12px;
    background: rgba(39,39,39,0.8);
}

.message{
    padding: 10px;
    margin: 10px 20px 5px 10px;
    font-size: 14px;
    background: #263A44;
    border-radius: 1em 1em 1em 0.1em;
    text-align: left;
}
.changeBook{
    position: absolute;
    bottom: 10px;
}
.book{
    padding: 10px;
    background: rgba(42,42,42, 0.6);
    border-radius: 10px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
    text-align: start;
}
.nextBtn{
    padding: 10px;
    cursor: pointer;
    margin: 0 20px 15px 10px;
    border-radius: 10px;
    font-size: 12px;
    background: rgba(38,58,68,0.7);
}
.btn{
    height: 5%;
    background: #28A8E9;
    padding: 10px;
    border-radius: 5px 5px 15px 15px;
    cursor: pointer;
    transition: transform 0.2s ease;
}
.btn:active {
    transform: scale(0.97)
}


.audioContainer{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.audioBtn{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    padding: 3px;
    background: #28A8E9;
    font-size: 14px;
    cursor: pointer;
}
.audioBtnIcon{
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
}
.audioInfo{
    font-size: 13px;
}
.audioInfo >div:last-child{
    color: #67757c;
}
.footer{
    height: 5vh;
    padding: 10px;
    color: #67757c;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: end !important;
}
.footer > div {
    width: fit-content;
    margin-right: 20px;
    cursor: pointer;
    font-size: 14px;
}
.footer > div:hover{
    color: #FFFFFF;
}

@media all and (max-width: 1000px) {
    .infoBlock {
        flex-direction: column;
    }
    .block{
        width: 100%;
        padding: 20px 0;
    }
}
@media all and (max-width: 680px) {
    .header{
        font-size: 4rem;
    }
    .cardsBlock{
        flex-direction: column;
    }
    .card {
        width: 50%;
        margin: 10px auto;
    }
    .startLogo{
        width: 200px;
        height: 200px;
    }
}
@media all and (max-width: 480px) {
    .container_1{
        padding: 0;
    }
    .infoBlock{
        width: 100%;
    }
    .block{
        box-sizing: border-box;
        width: 100%;
    }
    .block:first-child{
        padding: 10px;
    }
    .btnStart{
        width: 90%;
    }
    .phoneBorder{
        box-sizing: border-box;
        width: 100%;
        height: 100vh;
    }
    .phone{
        width: 98%;
        height: 98vh;
    }
    .message, .nextBtn{
        font-size: 18px;
    }
    .btn{
        font-size: 20px;
    }
    .book{
        font-size: 18px;
    }
    .cardsBlock{
        padding: 10px 0;
    }
    .card{
        width: 100%;
    }
}
