body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
    --background-theme-white: #FFFFFF;
    --background-containers-theme-white: #F4F4F4;
    --background-block-theme-white: #FCFCFC;
    --font-color-theme-white: #000000;

    --background-theme-beige: #F3E5C8;
    --background-containers-theme-beige: #FFF8E8;
    --background-block-theme-beige: #FEFDF9;
    --font-color-theme-beige: #000000;

    --background-theme-grey: #393333;
    --background-containers-theme-grey: #312B2B;
    --background-block-theme-grey: #413B3B;
    --font-color-theme-grey: #CFCECC;

    --background-theme-black: #000000;
    --background-containers-theme-black: #202020;
    --background-block-theme-black: #313131;
    --font-color-theme-black: #CFCECC;

    --background-theme-telegram: #000000;
    --background-containers-theme-telegram: #202020;
    --background-block-theme-telegram: #313131;
    --font-color-theme-telegram: #CFCECC;


    --background: var(--background-theme-white);
    --background-containers: var(--background-containers-theme-white);
    --background-block: var(--background-block-theme-white);
    --font-color: var(--font-color-theme-white);
}
