.userComponent {
    background: var(--background);
    color: var(--font-color);
}
.bookFeedContainer{
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.textContainer{
    padding: 0 5px 5px 5px;
    text-align: justify-all;
    transition: all 0.5s;
}

.choosingPostsContainer{
    padding: 10px;
}
.question{
    text-align: center;
    font-size: 20px;
    margin: 10px 10px 20px 10px;
}
.choosePost{
    height: fit-content;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
}
.choosePost > .paragraph::after{
    content: " ..."
}
.choosePost > .paragraph{
    margin-bottom: 0;
}
.paragraph{
    margin-bottom: 15px;
}
span:first-child{
    margin-left: 20px;
}
.buttonNext{
    margin-top: 10px;
    padding: 15px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    line-height: 1 !important;
    background: var(--background-containers);
}

h2, h3, h4 {
    width: 80%;
    margin: 5px;
}
img{
    max-width: 98%;
    margin: 5px;
}


.settingsContainer{
    background: var(--background-containers);
    box-sizing: border-box;
    height: 6vh;
    padding: 0 10px;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 20;
}

.bookTitle{
    font-size: 14px;
    padding: 10px;
}
.postsAheadContainer{
    display: flex;
    align-items: center;
    position: relative;
}
.postsAhead{
    text-align: center;
    right: 1.6rem;
    background: #E67571;
    color: white;
    padding: 0.1rem 0.3rem;
    border-radius: 0.75rem;
    font-size: 0.6rem !important;
    position: absolute;
    font-weight: bold;
}
.arrow{
    margin-left: 5px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    background: var(--background);
}
.buttonSet{
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
}
.settings {
    background: var(--background-containers);
    color: var(--font-color);
    box-sizing: border-box;
    position: fixed;
    padding: 10px;
    width: 100%;
    height: 35%;
    border-radius: 5px 5px 0 0;
    transition: all 0.5s;
    left: 0;
}

.settingsTabs{
    width: 80%;
    height: 4vh;
    display: flex;
    font-size: 22px !important;
    justify-content: space-between;
    text-align: center;
    margin: 0 auto 10px;
}
.settingsTabs > svg {
    cursor: pointer;
}

.block{
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.block > div:first-child{
    margin-bottom: 10px;
}
.settingBlock{
    background: var(--background-block);
    padding: 5px;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    font-size: 18px !important;
}

.telegramStyle{
    width: 40px;
    padding: 10px;
    text-align: center;
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    cursor: pointer;
}
.telegramStyle > svg {
}
.fontSet{
    margin: 20px 0;
    display: flex;
    align-items: center;
}
.fontSet > svg {
    font-size: 40px !important;
    margin: 0 10px;
    cursor: pointer;
}
.colors{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}
.colors > div {
    margin-right: 10px;
}
.colorSet{
    width: 40px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
.colorSetWhite {
    background: var(--background-theme-white);
    color: var(--font-color-theme-white);
}
.colorSetBeige {
    background: var(--background-theme-beige);
    color: var(--font-color-theme-beige);
}
.colorSetGrey {
    background: var(--background-theme-grey);
    color: var(--font-color-theme-grey);
}
.colorSetBlack {
    background: var(--background-theme-black);
    color: var(--font-color-theme-black);
}
.colorSetTelegram {
    background: var(--background-theme-telegram);
    color: var(--font-color-theme-telegram);
}
.colorSetActive{
    border: 1px solid #1f6a96;
}

.popupContainer {
    background: var(--background-containers);
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    width: 80%;
    height: fit-content;
    max-height: 250px;
    overflow: scroll;
    padding: 15px;
    box-shadow: 0px 0px 9px 0px rgba(31, 106, 150, 0.4);
    font-size: 20px !important;
}
.popupContainer > div:first-child{
    text-align: end;
    cursor: pointer;
    font-size: 20px !important;
}
.closeBtn{
    background: var(--background-block);
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px !important;
    margin-left: auto;
    cursor: pointer;
    font-size: 16px !important;
}


.BookContentContainer{
    background: var(--background-containers);
    color: var(--font-color);
    box-sizing: border-box;
    padding: 20px 10px 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    transition: all 0.5s;
    overflow: scroll;
    z-index: 10;
}
.title_1 {
    text-transform: uppercase;
    margin: 0 0 5px 5px;
    padding: 5px;
    cursor: pointer;
}
.title_2{
    position: relative;
    margin: 0 0 5px 20px;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
}
.title_3{
    position: relative;
    padding: 10px;
    margin: 0 0 5px 40px;
    cursor: pointer;
}
.title_4{
    position: relative;
    padding: 5px;
    margin: 0 0 5px 60px;
    cursor: pointer;
}

.playful > span {
    position: relative;
    color: #5362f6;
    text-shadow: 0.25px 0.25px #e485f8, 0.5px 0.5px #e485f8, 0.75px 0.75px #e485f8, 1px 1px #e485f8, 1.25px 1.25px #e485f8, 1.5px 1.5px #e485f8, 1.75px 1.75px #e485f8, 2px 2px #e485f8, 2.25px 2.25px #e485f8, 2.5px 2.5px #e485f8, 2.75px 2.75px #e485f8, 3px 3px #e485f8, 3.25px 3.25px #e485f8, 3.5px 3.5px #e485f8, 3.75px 3.75px #e485f8, 4px 4px #e485f8, 4.25px 4.25px #e485f8, 4.5px 4.5px #e485f8, 4.75px 4.75px #e485f8, 5px 5px #e485f8, 5.25px 5.25px #e485f8, 5.5px 5.5px #e485f8, 5.75px 5.75px #e485f8, 6px 6px #e485f8;
    animation: scatter 1.75s infinite;
}
.playful > span:after {
    content: "";
}

.playful span:nth-child(2n) {
    color: #ed625c;
    text-shadow: 0.25px 0.25px #f2a063, 0.5px 0.5px #f2a063, 0.75px 0.75px #f2a063, 1px 1px #f2a063, 1.25px 1.25px #f2a063, 1.5px 1.5px #f2a063, 1.75px 1.75px #f2a063, 2px 2px #f2a063, 2.25px 2.25px #f2a063, 2.5px 2.5px #f2a063, 2.75px 2.75px #f2a063, 3px 3px #f2a063, 3.25px 3.25px #f2a063, 3.5px 3.5px #f2a063, 3.75px 3.75px #f2a063, 4px 4px #f2a063, 4.25px 4.25px #f2a063, 4.5px 4.5px #f2a063, 4.75px 4.75px #f2a063, 5px 5px #f2a063, 5.25px 5.25px #f2a063, 5.5px 5.5px #f2a063, 5.75px 5.75px #f2a063, 6px 6px #f2a063;
    animation-delay: 0.3s;
}

.playful span:nth-child(3n) {
    color: #ffd913;
    text-shadow: 0.25px 0.25px #6ec0a9, 0.5px 0.5px #6ec0a9, 0.75px 0.75px #6ec0a9, 1px 1px #6ec0a9, 1.25px 1.25px #6ec0a9, 1.5px 1.5px #6ec0a9, 1.75px 1.75px #6ec0a9, 2px 2px #6ec0a9, 2.25px 2.25px #6ec0a9, 2.5px 2.5px #6ec0a9, 2.75px 2.75px #6ec0a9, 3px 3px #6ec0a9, 3.25px 3.25px #6ec0a9, 3.5px 3.5px #6ec0a9, 3.75px 3.75px #6ec0a9, 4px 4px #6ec0a9, 4.25px 4.25px #6ec0a9, 4.5px 4.5px #6ec0a9, 4.75px 4.75px #6ec0a9, 5px 5px #6ec0a9, 5.25px 5.25px #6ec0a9, 5.5px 5.5px #6ec0a9, 5.75px 5.75px #6ec0a9, 6px 6px #6ec0a9;
    animation-delay: 0.15s;
}

.playful span:nth-child(5n) {
    color: #555bff;
    text-shadow: 0.25px 0.25px #e485f8, 0.5px 0.5px #e485f8, 0.75px 0.75px #e485f8, 1px 1px #e485f8, 1.25px 1.25px #e485f8, 1.5px 1.5px #e485f8, 1.75px 1.75px #e485f8, 2px 2px #e485f8, 2.25px 2.25px #e485f8, 2.5px 2.5px #e485f8, 2.75px 2.75px #e485f8, 3px 3px #e485f8, 3.25px 3.25px #e485f8, 3.5px 3.5px #e485f8, 3.75px 3.75px #e485f8, 4px 4px #e485f8, 4.25px 4.25px #e485f8, 4.5px 4.5px #e485f8, 4.75px 4.75px #e485f8, 5px 5px #e485f8, 5.25px 5.25px #e485f8, 5.5px 5.5px #e485f8, 5.75px 5.75px #e485f8, 6px 6px #e485f8;
    animation-delay: 0.4s;
}

.playful span:nth-child(7n),
.playful span:nth-child(11n) {
    color: #ff9c55;
    text-shadow: 0.25px 0.25px #ff5555, 0.5px 0.5px #ff5555, 0.75px 0.75px #ff5555, 1px 1px #ff5555, 1.25px 1.25px #ff5555, 1.5px 1.5px #ff5555, 1.75px 1.75px #ff5555, 2px 2px #ff5555, 2.25px 2.25px #ff5555, 2.5px 2.5px #ff5555, 2.75px 2.75px #ff5555, 3px 3px #ff5555, 3.25px 3.25px #ff5555, 3.5px 3.5px #ff5555, 3.75px 3.75px #ff5555, 4px 4px #ff5555, 4.25px 4.25px #ff5555, 4.5px 4.5px #ff5555, 4.75px 4.75px #ff5555, 5px 5px #ff5555, 5.25px 5.25px #ff5555, 5.5px 5.5px #ff5555, 5.75px 5.75px #ff5555, 6px 6px #ff5555;
    animation-delay: 0.25s;
}

@keyframes scatter {
    0% {
        top: 0;
    }
    50% {
        top: -10px;
    }
    100% {
        top: 0;
    }
}
h1 {
    font-size: 50px;
    text-transform: uppercase;
    font-family: "Archivo Black", "Archivo", sans-serif;
    font-weight: normal;
    display: block;
    height: auto;
    text-align: center;
}

.fetchComponent{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.9;
    background: var(--background);
}
.loader{
    animation: 3s linear infinite rotate;
    position: fixed;
    font-size: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--font-color);
}
@keyframes rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
